<template>
  <EtNavbar>
    <template #title="{ closeMenu }">
      <RouterLink
        :to="{ name: routeNames.HomePage }"
        @click="closeMenu">
        {{ $t('layout.theNavigationBar.title') }}
      </RouterLink>
    </template>
    <template #nav1items>
      <EtNavbarItem>
        <a
          :href="wwwLink"
          target="_blank">
          {{ $t('layout.theNavigationBar.gotoEt') }}
        </a>
      </EtNavbarItem>
    </template>
    <template #nav2items="{ closeMenu }">
      <EtNavbarItem icon="add">
        <RouterLink
          v-if="userStore.isETAdmin"
          :to="{ name: routeNames.ListingsCreate }"
          @click="closeMenu">
          {{ $t('layout.theNavigationBar.newListing') }}
        </RouterLink>
      </EtNavbarItem>
      <EtNavbarItem icon="user">
        <a
          v-if="!userStore.isAuthenticated"
          @click="onLogin">
          {{ $t('layout.theNavigationBar.login') }}
        </a>
        <RouterLink
          v-else
          :to="{ name: routeNames.UserProfileIndex }"
          @click="closeMenu">
          {{ $t('layout.theNavigationBar.loggedOnAs', { email: userStore.user?.email }) }}
        </RouterLink>
      </EtNavbarItem>
    </template>
    <template
      v-if="!route.meta.public || userStore.isETAdmin"
      #nav3items>
      <RouterLink
        v-for="child in links"
        v-slot="{ href, navigate, isActive }"
        :key="child.label"
        :to="{ name: child.routeName }"
        custom>
        <EtNavbarItem
          :is-active="isActive"
          :href="href"
          @click="navigate">
          {{ child.label }}
        </EtNavbarItem>
      </RouterLink>
    </template>
  </EtNavbar>
</template>

<script setup>
  import { EtNavbar, EtNavbarItem } from 'vue-ui-kit';
  import { useKindeAuth } from '@common/composables/auth/authPlugin.js';
  import { RouterLink, useRoute } from 'vue-router';
  import { routeNames } from '@/router';
  import { useT } from '@common/i18n/i18n.js';
  import { useUserStore } from '@common/store/index.js';
  const t = useT();
  const route = useRoute();
  const userStore = useUserStore();
  const { login } = useKindeAuth();

  const onLogin = () => {
    login({ fullPath: '/' });
  };

  const wwwLink = import.meta.env.VITE_PATH_WWW;

  const links = [
    {
      label: t('layout.theNavigationBar.listings', 2),
      routeName: routeNames.ListingsIndex,
    },
    {
      label: t('layout.theNavigationBar.users'),
      routeName: routeNames.UsersIndex,
    },
    {
      label: t('layout.theNavigationBar.organizations'),
      routeName: routeNames.OrganizationsIndex,
    },
    {
      label: t('layout.theNavigationBar.accounts'),
      routeName: routeNames.AccountsIndex,
    },
    {
      label: t('layout.theNavigationBar.listingSuppliers'),
      routeName: routeNames.ListingSuppliersIndex,
    },
  ];
</script>
