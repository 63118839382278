// @ts-check
import { authGuard } from '@common/composables/auth/authGuard.js';
import { useUserStore } from '@common/store/index.js';
import { routeNames } from './index.js';

/**
 * @param {import('vue-router').RouteLocation} to Where to
 * @returns {Promise<boolean|object>} Returns bool or object that tells router to continue or not
 */
export const privateGuard = async (to) => {
  // If public stop here.
  if (to.meta.public) {
    return;
  }

  // 0. step. If state from Kinde, then just stop here because there will be a redirect in authPlugin on_redirect_callback
  if (to.query?.state != null) {
    return;
  }

  // 1. step. Is user authenticated at kinde. If not, then goto login
  const result = await authGuard(to);
  if (result === false) {
    return result;
  }

  const userStore = useUserStore();
  await userStore.whenReady();

  // 2. step. Only et-admin is allowed
  if (!userStore.isETAdmin) {
    return { name: routeNames.NotAdmin };
  }

  return true;
};
