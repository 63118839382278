<script setup>
  // @ts-check
  import { computed } from 'vue';
  import { useRoute } from 'vue-router';
  import TheNavigationBar from '@/layout/TheNavigationBar.vue';
  import KindeError from '@common/components/error/KindeError.vue';
  import { EtLoader } from 'vue-ui-kit';
  import { useKindeAuth } from '@common/composables/auth/authPlugin.js';

  const route = useRoute();
  const kinde = useKindeAuth();

  const isAuth = computed(() => route.query.code != null && route.query.state != null);
  const isReady = computed(() => route.name != null && !kinde.isLoading.value && !isAuth.value);
</script>

<template>
  <TheNavigationBar
    v-if="isReady" />
  <RouterView v-if="isReady" />
  <KindeError v-if="kinde.hasError.value" />
  <EtLoader
    :loading="!isReady && !kinde.hasError.value"
    is-page />
</template>
