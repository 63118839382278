export const privateRoutes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/home/HomePage.vue'),
  },
  {
    path: '/user',
    name: 'UserProfileIndex',
    component: () => import('@/views/user/UserProfileIndex.vue'),
  },
  {
    path: '/listings',
    name: 'ListingsIndex',
    component: () => import('@/views/listings/ListingsIndex.vue'),
  },
  {
    path: '/listings/create',
    name: 'ListingsCreate',
    component: () => import('@/views/listings/ListingsCreate.vue'),
  },

  {
    path: '/accounts',
    name: 'AccountsIndex',
    redirect: { name: 'AccountsList' },
    // component: () => import('@/views/account/AccountsIndex.vue'),
    children: [
      {
        path: '',
        name: 'AccountsList',
        component: () => import('@/views/account/AccountsList.vue'),
      },
      {
        path: ':id',
        name: 'AccountShow',
        component: () => import('@/views/account/AccountShow.vue'),
      },
      {
        path: ':id/update',
        name: 'AccountsUpdate',
        component: () => import('@/views/account/AccountForm.vue'),
      },
      {
        path: 'create',
        name: 'AccountsCreate',
        component: () => import('@/views/account/AccountForm.vue'),
      },
    ],
  },

  {
    path: '/users',
    name: 'UsersIndex',
    component: () => import('@/views/users/UsersIndex.vue'),
  },
  {
    path: '/users/create',
    name: 'UsersCreate',
    component: () => import('@/views/users/UsersCreate.vue'),
  },
  {
    path: '/users/:id',
    name: 'UsersShow',
    component: () => import('@/views/users/UsersShow.vue'),
  },

  {
    path: '/organizations',
    name: 'OrganizationsIndex',
    component: () => import('@/views/organization/OrganizationsIndex.vue'),
  },
  {
    path: '/organization/create',
    name: 'OrganizationsCreate',
    component: () => import('@/views/organization/OrganizationsCreate.vue'),
  },
  {
    path: '/organization/:id',
    name: 'OrganizationsShow',
    component: () => import('@/views/organization/OrganizationsShow.vue'),
  },

  {
    path: '/listingsuppliers',
    name: 'ListingSuppliersIndex',
    component: () => import('@/views/listingSupplier/ListingSuppliersIndex.vue'),
  },
  {
    path: '/listingsupplier/create',
    name: 'ListingSuppliersCreate',
    component: () => import('@/views/listingSupplier/ListingSuppliersCreate.vue'),
  },
  {
    path: '/listingsupplier/:id',
    name: 'ListingSuppliersShow',
    component: () => import('@/views/listingSupplier/ListingSuppliersShow.vue'),
  },

  {
    path: '/listings/rental/',
    name: 'RentalListingsIndex',
    component: () => import('@/views/rentalListing/RentalListingsIndex.vue'),
    children: [
      {
        path: ':id',
        name: 'RentalListingsShow',
        component: () => import('@/views/rentalListing/RentalListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'RentalListingsUpdate',
        component: () => import('@/views/rentalListing/RentalListingForm.vue'),
      },
      {
        path: 'create',
        name: 'RentalListingsCreate',
        component: () => import('@/views/rentalListing/RentalListingForm.vue'),
      },
      {
        path: ':id/close',
        name: 'RentalListingsClose',
        component: () => import('@/views/rentalListing/RentalListingsClose.vue'),
      },
    ],
  },
  {
    path: '/listings/investment/',
    name: 'InvestmentListingsIndex',
    component: () => import('@/views/investmentListing/InvestmentListingsIndex.vue'),
    children: [
      {
        path: ':id',
        name: 'InvestmentListingsShow',
        component: () => import('@/views/investmentListing/InvestmentListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'InvestmentListingsUpdate',
        component: () => import('@/views/investmentListing/InvestmentListingForm.vue'),
      },
      {
        path: 'create',
        name: 'InvestmentListingsCreate',
        component: () => import('@/views/investmentListing/InvestmentListingForm.vue'),
      },
      {
        path: ':id/close',
        name: 'InvestmentListingsClose',
        component: () => import('@/views/investmentListing/InvestmentListingsClose.vue'),
      },
    ],
  },
  {
    path: '/listings/sales/',
    name: 'SalesListingsIndex',
    component: () => import('@/views/salesListing/SalesListingsIndex.vue'),
    children: [
      {
        path: ':id',
        name: 'SalesListingsShow',
        component: () => import('@/views/salesListing/SalesListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'SalesListingsUpdate',
        component: () => import('@/views/salesListing/SalesListingForm.vue'),
      },
      {
        path: 'create',
        name: 'SalesListingsCreate',
        component: () => import('@/views/salesListing/SalesListingForm.vue'),
      },
      {
        path: ':id/close',
        name: 'SalesListingsClose',
        component: () => import('@/views/salesListing/SalesListingsClose.vue'),
      },
    ],
  },
  {
    path: '/listings/officespace/',
    name: 'OfficespaceListingsIndex',
    component: () => import('@/views/officespaceListing/OfficespaceListingsIndex.vue'),
    children: [
      {
        path: ':id',
        name: 'OfficespaceListingsShow',
        component: () => import('@/views/officespaceListing/OfficespaceListingsShow.vue'),
      },
      {
        path: ':id/update',
        name: 'OfficespaceListingsUpdate',
        component: () => import('@/views/officespaceListing/OfficespaceListingForm.vue'),
      },
      {
        path: 'create',
        name: 'OfficespaceListingsCreate',
        component: () => import('@/views/officespaceListing/OfficespaceListingForm.vue'),
      },
      {
        path: ':id/close',
        name: 'OfficespaceListingsClose',
        component: () => import('@/views/officespaceListing/OfficespaceListingsClose.vue'),
      },
    ],
  },
];

/**
 * Extracts names from routes and children to object of { name: name, name2: name2, ...}
 */
export const privateRouteNames = privateRoutes
  .flatMap(r => [r.name, ...(r.children ? r.children.map(rr => rr.name) : [null])].filter(f => f))
  .reduce((a, v) => ({ ...a, [v]: v }), {});
